<template>
  <div class="author-list">
    <van-nav-bar fixed title="专家列表" left-text="返回" left-arrow @click-left="onClickLeft" />

    <div style="padding: 16px;z-index:10;background:#fff">
      <a-input-search placeholder="搜索专家姓名" @search="onSearch" v-model="search" enter-button="搜索">
        <a-icon slot="prefix" type="search"></a-icon>
      </a-input-search>
    </div>
    <a-select class="select" @change="handleChange" placeholder="选择专家组别">
      <a-select-option value="全部">全部</a-select-option>
      <a-select-option :value="item" v-for="(item, index) in authorCategory" :key="index">{{ item }}</a-select-option>
    </a-select>

    <div class="list" v-if="isShow">
      <flow-list url="home/author" :params="params" :sep="false">
        <template slot-scope="row">
          <div class="item-wrap">
            <author-list-item :author="row.itemData"></author-list-item>
          </div>
        </template>
      </flow-list>
    </div>
  </div>
</template>

<script>
import { FlowList, TopHeader } from '@/components';

import { backInitMixin } from '@/utils/mixin';
import AuthorListItem from '@/components/AuthorListItem';
export default {
  name: 'AuthorList',
  components: {
    FlowList,

    TopHeader,

    AuthorListItem
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      name: '',
      isShow: true,
      authorData: [],
      authorCategory: [],
      search: ''
    };
  },
  computed: {},

  watch: {},
  created() {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      order_by: 'posts desc',
      filters: {}
    };
  },
  activated() {
    // this.track()
  },
  mounted() {
    this._getAuthorList();
  },
  methods: {
    onSearch(value) {
      console.log(value, this.$refs, 'refs');
      value = value.replace(/(^\s*)|(\s*$)/g, '');

      this.isShow = false;
      this.params.limit_start = 0;
      if (!value) {
        delete this.params.filters;
      } else {
        this.params.filters.pen_name = ['like', `%${value}%`];
      }

      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    _getAuthorList() {
      this.$api['home/authorCategory']().then(res => {
        if (res.message) {
          this.authorCategory = res.message;
        }
      });
    },
    handleChange(value) {
      console.log('value', value);
      this.isShow = false;
      if (value === '全部') {
        delete this.params.filters.external_group;
      } else {
        this.params.filters.external_group = value;
      }

      this.$nextTick(() => {
        this.isShow = true;
      });
    }
  }
};
</script>

<style scoped lang="less">
.author-list {
  padding-top: 46px;
}
.select {
  margin: 10px 16px;
  width: calc(100% - 32px);
  z-index: 100;
}
.list {
  padding: 0 16px;
  background: #fff;
}
.item-wrap {
  margin: 16px 0;
}
/deep/ .van-pull-refresh {
  overflow: visible;
}
/deep/ .ant-timeline-item-tail {
  border-left-color: #c7d4f7;
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
</style>
